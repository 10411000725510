export default {
    downloadKonvaImage(konvaRef, filename = 'file.png', mimeType = 'image/png') {
        function downloadURI(uri, name) {
            var link = document.createElement('a');
            link.download = name;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            //   delete link;
        }

        const stage =  konvaRef.getStage()
        var dataURL = stage.toDataURL({mimeType: mimeType, pixelRatio: 3 });
        downloadURI(dataURL, filename);
    },

    getFile(konvaRef) {
        function dataURItoBlob(dataURI) {
            // convert base64 to raw binary data held in a string
            // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
            var byteString = atob(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

            // write the bytes of the string to an ArrayBuffer
            var ab = new ArrayBuffer(byteString.length);

            // create a view into the buffer
            var ia = new Uint8Array(ab);

            // set the bytes of the buffer to the correct values
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            // write the ArrayBuffer to a blob, and you're done
            var blob = new Blob([ab], {type: mimeString});
            return blob;

        }

        const stage =  konvaRef.getStage()
        var dataURL = stage.toDataURL({ pixelRatio: 3 });
        return dataURItoBlob(dataURL)
    },

    loadImage(src, width = 100) {
        return new Promise(resolve => {
            const image = new Image();
            image.crossOrigin = "Anonymous";
            image.src = src;
            image.width = width;

            image.addEventListener('load', () => {
                image.height = image.height * (image.width / image.naturalWidth)
                resolve(image);
            });
        })
    },

    updateTransformer(transformer, selectedShapeName) {
        // here we need to manually attach or detach Transformer node
        const transformerNode = transformer.getNode();
        const stage = transformerNode.getStage();

        const selectedNode = stage.findOne('.' + selectedShapeName);
        // do nothing if selected node is already attached
        if (selectedNode === transformerNode.node()) {
            return;
        }

        if (selectedNode) {
            // attach to another node
            transformerNode.nodes([selectedNode]);
        } else {
            // remove transformer
            transformerNode.nodes([]);
        }
        transformerNode.getLayer().batchDraw();
    },
    handleTransformEnd(e, config) {
        config.x = e.target.x();
        config.y = e.target.y();
        config.rotation = e.target.rotation();
        config.scaleX = e.target.scaleX();
        config.scaleY = e.target.scaleY();
        return config
    }


}
